// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
// require("data-confirm-modal")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery-validation');
require("micromodal")

const jstz = require('jstimezonedetect');
window.jstz = jstz

import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS

require("packs/notifications.js")
// require("packs/copytoclipboard.js")
require("packs/manage_cookies.js")

import $ from 'jquery';
import MenuButton from 'inclusive-menu-button'
import "bootstrap"
import "../stylesheets/landing"
import "@fortawesome/fontawesome-free/css/all"
import 'inclusive-menu-button'
const Tabby = require('tabbyjs/dist/js/tabby.polyfills')
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

$(document).on('turbolinks:load', function () {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
    trigger: 'hover',
  }).click(function () {
    $('[data-toggle="tooltip"]').tooltip("hide");
  });

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });

  var menuButtons = document.querySelectorAll('[data-inclusive-menu-opens]')
  for (var i = 0; i < menuButtons.length; i++) {
    new MenuButton(menuButtons[i])
  }

  // Mobile Menu Toggle
  var toggle = document.getElementById("mobile-menu-toggle")
  var toggleIcon = document.querySelector("#mobile-menu-toggle i")
  var nav = document.getElementById("mobile-nav")

  if (toggle) {
    toggle.addEventListener("click", function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      nav.classList.toggle("hidden");
      swapMenuPosition();
      toggle.ariaLabel = nav.classList.contains("hidden") ? "Open Menu" : "Close Menu"
      if (toggleIcon.classList.contains("fa-bars")) {
        toggleIcon.classList.remove("fa-bars");
        toggleIcon.classList.add("fa-times");
      } else {
        toggleIcon.classList.remove("fa-times");
        toggleIcon.classList.add("fa-bars");
      }
    }, false);
  }

  var swapMenuPosition = function () {
    var dropdown = document.querySelector('[data-inclusive-menu-from]')
    if (dropdown.getAttribute("data-inclusive-menu-from") === 'right') {
      dropdown.setAttribute("data-inclusive-menu-from", "left");
    } else {
      dropdown.setAttribute("data-inclusive-menu-from", "right");
    }
  }

  // become creator testimony tabs
  if (document.querySelector('[data-tabs]')) {
    var tabs = new Tabby('[data-tabs]');
  }

  //this allows us to use html disabled attribute in rails
  //to prevent clicking on a disabled link from doing anything
  $('a[disabled]').click(function (e) {
    e.stopImmediatePropagation()
    e.preventDefault();
  });
});


// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
//   $('[data-toggle="popover"]').popover({ trigger: "hover" });
// });
// require("packs/autocomplete.js")

const application = Application.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "stylesheets/landing"
