import $ from "jquery";

document.addEventListener('turbolinks:load', () => {  
    // create a cookie
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // read a cookie

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
        }
        return "";
    }
    var token_data_field = document.getElementById('invitation-token')
    if (token_data_field){
        var token_value = token_data_field.dataset.token
        if(token_value){
            setCookie("invitation_token", token_value, 30)
        }
    }

    var cookie = getCookie("invitation_token")
    var token_field = document.getElementById('invitation-token-field')
    if (token_field){
        token_field.value = cookie
    }
})