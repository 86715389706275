document.addEventListener('turbolinks:load', () => {
  let bellIcon = document.querySelector('#bellIcon')
  let notificationNumber = document.querySelector('.bubble-notification')
  let dropDownMenu = document.querySelector('.dropdown-menu')

  getNotificationCount()

  $('#bellIcon').click(()=> {
    $.ajax({
      url:  "/notifications",
      type: "get",
      data: {},
      dataType: "script",
      success: (result) => {
        updateNotifications()
      }
    });
  })

  function getNotificationCount () {
    if (notificationNumber) {
      $.ajax({
       type: "get",
       contentType: "application/json; charset=utf-8",
       url: "/notifications_count",
       data: '',
       dataType: "json",
       success: function (result) {
          if (result.count > 0) {
            notificationNumber.style.visibility = 'visible'
            notificationNumber.textContent = result.count
          }
          else {
            notificationNumber ? notificationNumber.style.visibility = 'hidden' : null;
          }
       }
      });
    }
  }

  function updateNotifications () {
    $.ajax({
      url:  "/notifications_update",
      type: "get",
      data: {},
      dataType: "script"
    });
    notificationNumber.style.visibility = 'hidden'
  }

})
