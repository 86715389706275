import consumer from "./consumer"

consumer.subscriptions.create("ArticleNotificationsChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content > 0) {
      $('.notification-number').css('visibility', 'visible')
      $('.notification-number').text(data.content)
    } 
  }
});
